(function( $ ) {

    $.fn.mobileCatalogMenu = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var mainCatalogOpener = jqContainer.find('>a');
            if (mainCatalogOpener.length <= 0) {
                console.error('mobileCatalogMenu: no mainCatalogOpener found by selector ">a" in ', this);
                return false;
            }
            var mainCatalogCloser = jqContainer.find('.back.main-lvl>a');
            if (mainCatalogCloser.length <= 0) {
                console.error('mainCatalogCloser: no mainCatalogCloser found by selector ".back.main-lvl>a" in ', this);
                return false;
            }
            mainCatalogOpener.click(function(e) {
                e.preventDefault();
                jqContainer.addClass('open');
            });
            mainCatalogCloser.click(function(e) {
                e.preventDefault();
                jqContainer.removeClass('open');
            });
            var subMenues = jqContainer.find('ul.main-level>li.has-sub-menu');
            subMenues.each(function() {
                var jqSubLevel = $(this);
                var subCatalogOpener = jqSubLevel.find('>a');
                if (subCatalogOpener.length <= 0) {
                    console.error('mobileCatalogMenu: no subCatalogOpener found by selector ">a" in ', this);
                    return false;
                }
                var subCatalogCloser = jqSubLevel.find('.back.sub-lvl>a');
                if (mainCatalogCloser.length <= 0) {
                    console.error('mainCatalogCloser: no subCatalogCloser found by selector ".back.main-lvl>a" in ', this);
                    return false;
                }
                subCatalogOpener.click(function(e) {
                    e.preventDefault();
                    jqSubLevel.addClass('open');
                });
                subCatalogCloser.click(function(e) {
                    e.preventDefault();
                    jqSubLevel.removeClass('open');
                });
            });
        }); 

        return this;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.catalog-menu-link.mobile-catalog').mobileCatalogMenu();
});
