(function( $ ) {

    $.fn.searchPanel = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var openLink = jqContainer.find('>a');
            if (openLink.length <= 0) {
                console.log('searchPanel: no open link found in:', this);
                return false;
            }
            openLink.click(function(e) {
                e.preventDefault();
                if (!jqContainer.hasClass('open')) {
                    jqContainer.addClass('open');
                }
            });

            $('body').keyup(function(e) {
                if(e.key === "Escape") {
                    if (jqContainer.hasClass('open')) {
                        jqContainer.removeClass('open');
                    }
                }
            });  

        });

        return this;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('header .search').searchPanel();
});
